"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@mui/material/styles");
var styles_2 = require("@mui/material/styles");
var theme = (0, styles_1.createTheme)({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1280,
            lg: 1440,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            main: '#282F40',
            light: '#F4F7FF',
        },
        secondary: {
            main: '#364772',
            light: (0, styles_2.lighten)('#282F40', 0.9)
        },
        info: {
            main: "#FFFFFF",
        },
        text: {
            primary: '#323C55',
            secondary: '#FFFFFF',
            disabled: "#525D78",
        },
        background: {
            default: '#FFFFFF',
            paper: '#F4F7FF',
        },
    },
    typography: {
        fontSize: 18,
        fontFamily: ['Milyuna, Times New Roman', 'Arial'].join(','),
        h1: {
            fontFamily: ['Milyuna', 'Times New Roman', 'Arial'].join(','),
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontSize: "4rem",
            '@media (max-width:800px)': {
                fontSize: "2rem",
                fontWeight: 'normal'
            },
        },
        h2: {
            fontFamily: ['Pinyon', 'Montserrat', 'Arial'].join(','),
            fontSize: '8rem',
            '@media (max-width:800px)': {
                fontSize: "2.5rem",
                fontWeight: 'normal'
            },
        },
        h3: {
            fontFamily: ["Milyuna", 'Times New Roman', 'Arial'].join(','),
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontSize: "2.75rem",
            '@media (max-width:800px)': {
                fontSize: "2rem",
                fontWeight: 'normal'
            },
        },
        h4: {
            fontFamily: ['Pinyon', 'Montserrat', 'Arial'].join(','),
            fontSize: "4rem",
            '@media (max-width:800px)': {
                fontSize: "2.5rem",
                fontWeight: 'normal'
            },
        },
        h5: {
            fontFamily: ['Pinyon, Montserrat, Arial'].join(','),
            fontSize: "2.25rem",
            '@media (max-width:800px)': {
                fontSize: "1.75rem",
                fontWeight: 'normal'
            }
        },
        h6: {
            fontFamily: ['Milyuna', 'Times New Roman', 'Arial'].join(','),
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontSize: "1.5rem",
            '@media (max-width:800px)': {
                fontSize: "1.1rem",
                fontWeight: 'normal'
            },
        },
        body1: {
            fontFamily: ['Montserrat', 'Arial'].join(','),
            fontSize: "1.25rem",
            '@media (max-width:800px)': {
                fontSize: "1.1rem",
                lineHeight: '30px',
            },
            lineHeight: '40px',
        },
        body2: {
            fontFamily: ['Pinyon', 'Montserrat', 'Arial'].join(','),
            fontSize: '2rem',
        }
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: function (theme) { return ({
                    color: "#323C55",
                    width: '100%',
                    '.MuiInputBase-input': {
                        color: "#323C55",
                    },
                    '.MuiInputLabel-root': {
                        color: "#323C55",
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: "#323C55",
                    },
                }); },
            },
        }
    }
});
exports.default = theme;
