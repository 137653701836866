// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Milyuna Italic.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/PinyonScript-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Montserrat-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Milyuna Italic';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
    font-family: 'Pinyon';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
    font-family: 'Montserrat';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,4CAA6C;AACjD;;AAEA;IACI,qBAAqB;IACrB,4CAAmD;AACvD;;AAEA;IACI,yBAAyB;IACzB,4CAA2D;AAC/D;;AAEA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B","sourcesContent":["@font-face {\n    font-family: 'Milyuna Italic';\n    src: url('./assets/fonts/Milyuna Italic.otf');\n}\n\n@font-face {\n    font-family: 'Pinyon';\n    src: url('./assets/fonts/PinyonScript-Regular.otf');\n}\n\n@font-face {\n    font-family: 'Montserrat';\n    src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf');\n}\n\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
