"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var titleMap = {
    '/schedule': 'C&A | Schedule',
    '/event-details': 'C&A | Event Details',
    '/travel-&-stay': 'C&A | Travel & Stay',
    '/gallery': 'C&A | Gallery',
    '/gallery/proposal': 'C&A | Proposal',
    '/gallery/engagements': 'C&A | Engagements',
    '/registry': 'C&A | Registry',
    '/frequently-asked-questions': 'C&A | FAQs',
    '/rsvp': 'C&A | RSVP',
};
function getTitle(pathname) {
    for (var key in titleMap) {
        if (pathname.includes(key)) {
            return titleMap[key];
        }
    }
    return 'C&A | Home';
}
function PageTitleUpdater() {
    var location = (0, react_router_dom_1.useLocation)();
    (0, react_1.useEffect)(function () {
        var title = getTitle(location.pathname);
        document.title = title;
    }, [location]);
    return null;
}
;
exports.default = PageTitleUpdater;
