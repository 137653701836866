"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eventDetails = void 0;
exports.eventDetails = {
    Wedding: {
        title: 'The Wedding',
        date: '04.04.2025, 5:30PM - 11:30PM',
        location: 'Mesa, AZ',
        venue: 'Lindsay Grove',
    },
    Rehearsal: {
        title: 'The Rehearsal',
        date: '04.03.2025, 10AM - 11AM',
        location: 'Mesa, AZ',
        venue: 'Lindsay Grove',
    },
    RehearsalDinner: {
        title: 'The Rehearsal Dinner',
        date: '04.03.2025, 5PM - 8PM',
        location: 'Scottsdale, AZ',
        venue: 'North Italia',
    },
};
