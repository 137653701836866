"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.slideshowImages = void 0;
var importAll = function (r) {
    var images = r.keys().map(function (fileName) { return ({
        src: r(fileName).default,
        alt: "Slideshow - " + fileName.replace('./', '').replace(/\.[^/.]+$/, ''),
    }); });
    return images;
};
exports.slideshowImages = importAll(require.context('./', false, /\.(png)$/));
