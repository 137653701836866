"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.engagementPhotos = void 0;
var importAll = function (r) {
    var images = r.keys().map(function (fileName) { return ({
        src: r(fileName).default,
        alt: fileName.replace('./', '').replace(/\.[^/.]+$/, ''),
    }); });
    return images;
};
exports.engagementPhotos = importAll(require.context('./', false, /\.(jpg)$/));
